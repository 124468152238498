import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, ErrorHandler, Injectable, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { SportsbookCoreModule } from '@egamings/sportsbook-core';
import { SportsbookModuleSystemModule } from '@egamings/sportsbook-core/sportsbook-module-system';

import { AppComponent } from './app.component';

import { SiteConfig } from 'app/config/site.config';
import { InjectorService } from 'app/services/injector/injector.service';


import { environment } from '../environments/environment';

import * as Sentry from '@sentry/browser';

// Languages
const availableLanguages: string[] = ['ar', 'az', 'br', 'cs', 'de', 'el', 'en', 'es', 'fi', 'fr', 'fr-ca', 'hi', 'it', 'uk', 'ja', 'ko', 'lv', 'mn', 'pl', 'pt', 'ru', 'th', 'tr', 'zh', 'sr'];

import { registerLocaleData } from '@angular/common';

import localeAr from '@angular/common/locales/ar';
import localeAz from '@angular/common/locales/az';
import localeBr from '@angular/common/locales/br';
import localeCs from '@angular/common/locales/cs';
import localeDe from '@angular/common/locales/de';
import localeEl from '@angular/common/locales/el';
import localeEs from '@angular/common/locales/es';
import localeFi from '@angular/common/locales/fi';
import localeFr from '@angular/common/locales/fr';
import localeFrCa from '@angular/common/locales/fr-CA';
import localeHi from '@angular/common/locales/hi';
import localeIt from '@angular/common/locales/it';
import localeJa from '@angular/common/locales/ja';
import localeUk from '@angular/common/locales/uk';
import localeKo from '@angular/common/locales/ko';
import localeLv from '@angular/common/locales/lv';
import localeMn from '@angular/common/locales/mn';
import localePl from '@angular/common/locales/pl';
import localePt from '@angular/common/locales/pt';
import localeRu from '@angular/common/locales/ru';
import localeSq from '@angular/common/locales/sq';
import localeTh from '@angular/common/locales/th';
import localeTr from '@angular/common/locales/tr';
import localeZh from '@angular/common/locales/zh';
import localeSr from '@angular/common/locales/sr';

import 'dayjs/locale/ar';
import 'dayjs/locale/az';
import 'dayjs/locale/br';
import 'dayjs/locale/cs';
import 'dayjs/locale/de';
import 'dayjs/locale/el';
import 'dayjs/locale/es';
import 'dayjs/locale/fi';
import 'dayjs/locale/fr';
import 'dayjs/locale/fr-ca';
import 'dayjs/locale/hi';
import 'dayjs/locale/it';
import 'dayjs/locale/uk';
import 'dayjs/locale/ja';
import 'dayjs/locale/ko';
import 'dayjs/locale/lv';
import 'dayjs/locale/pl';
import 'dayjs/locale/pt';
import 'dayjs/locale/ru';
import 'dayjs/locale/th';
import 'dayjs/locale/tr';
import 'dayjs/locale/zh';
import 'dayjs/locale/sr';
import 'dayjs/locale/sq';

import { SportsbookCommonModule } from '@egamings/sportsbook-core/sportsbook-common';
import { SportsbookRouterModule } from '@egamings/sportsbook-core/sportsbook-router';
import { UIRouterModule } from '@uirouter/angular';
import { SportsbookDynamicComponentLoaderModule } from '@egamings/sportsbook-core/sportsbook-dynamic-component-loader';
import { SPORTSBOOK_DYNAMIC_COMPONENTS_MANIFEST } from '@egamings/sportsbook-core/sportsbook-dynamic-components';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

registerLocaleData(localeAr);
registerLocaleData(localeAz);
registerLocaleData(localeBr);
registerLocaleData(localeCs);
registerLocaleData(localeDe);
registerLocaleData(localeEl);
registerLocaleData(localeEs);
registerLocaleData(localeFi);
registerLocaleData(localeFr);
registerLocaleData(localeFrCa);
registerLocaleData(localeHi);
registerLocaleData(localeIt);
registerLocaleData(localeJa);
registerLocaleData(localeUk);
registerLocaleData(localeRu);
registerLocaleData(localeKo);
registerLocaleData(localeZh);
registerLocaleData(localeMn);
registerLocaleData(localePl);
registerLocaleData(localePt);
registerLocaleData(localeTh);
registerLocaleData(localeTr);
registerLocaleData(localeLv);
registerLocaleData(localeSr);
registerLocaleData(localeSq);

// Sentry
const env = environment.env ? environment.env : 'qa';
if (!env.includes('dev') && environment.sentry) {
    Sentry.init({
        dsn: environment.sentry,
        environment: env
    });
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
    constructor() { }

    handleError(error) {
        if (env.includes('dev')) {
            console.error(error.originalError || error);
        } else {
            Sentry.captureException(error.originalError || error);
        }
    }
}


function appLoadFactory(config: SiteConfig, http: HttpClient, translateService: TranslateService) {
    const lang = config.getLanguage();

    return async () => {
        await Promise.all([
            (async () => {
                if (config.isPrefetching()) {
                    return;
                }

                return await config.init();
            })(),
            (async () => {
                await http.get(`/static/i18n/${lang}.json?v=15`).pipe(
                    catchError(() => {
                        return http
                            .get(`/static/i18n/en.json?v=15`)
                            .pipe(catchError(() => of(null)));
                    }),
                    map(r => {
                        if (!r) {
                            return;
                        }

                        const customTrans = config.getCustomTranslations(lang);

                        if (customTrans) {
                            for (let i in customTrans) {
                                r[i] = customTrans[i];
                            }
                        }

                        translateService.setTranslation(lang, r);
                    }),
                )
                .toPromise();
            })(),
        ]);
    };
}

@NgModule({
    declarations: [
        AppComponent,
    ],
    exports: [

    ],
    entryComponents: [
    ],
    imports: [
        FormsModule,
        BrowserModule,
        HttpClientModule,
        SportsbookCoreModule.forRoot({
            config: {
                useFactory: function (siteConfig: SiteConfig): any {
                    return siteConfig.getSportsbookCoreConfig();
                },
                deps: [SiteConfig],
            },
        }),
        SportsbookCommonModule.forRoot({
            useFactory: (siteConfig: SiteConfig) => {
                return siteConfig.getSportsbookCommonConfig();
            },
            deps: [SiteConfig],
        }),
        SportsbookDynamicComponentLoaderModule.forRoot(SPORTSBOOK_DYNAMIC_COMPONENTS_MANIFEST({
            importGamesPreview: () => import('@egamings/sportsbook-core/sportsbook-dynamic-components/sportsbook-games-preview').then(m => m.SportsbookGamesPreviewModule),
            importGamesByDays: () => import('@egamings/sportsbook-core/sportsbook-dynamic-components/games-by-days').then(m => m.GamesByDaysModule),
            importGameMiniTracker: () => import('@egamings/sportsbook-core/sportsbook-dynamic-components/game-mini-tracker').then(m => m.GameMiniTrackerModule),
            importTopLeaguesSlider: () => import('@egamings/sportsbook-core/sportsbook-dynamic-components/top-leagues-slider').then(m => m.TopLeaguesSliderModule),
            importBigWins: () => import('@egamings/sportsbook-core/sportsbook-dynamic-components/big-wins').then(m => m.BigWinsModule),
            importBetSlipLoader: () => import('@egamings/sportsbook-core/sportsbook-dynamic-components/bet-slip-loader').then(m => m.BetSlipLoaderModule),
            importGamesByTime: () => import('@egamings/sportsbook-core/sportsbook-dynamic-components/games-by-time').then(m => m.GamesByTimeModule),
            importGameEsportHeader: () => import('@egamings/sportsbook-core/sportsbook-dynamic-components/game-esport-header').then(m => m.GameEsportHeaderModule),
            importGameHeader: () => import('@egamings/sportsbook-core/sportsbook-dynamic-components/game-header').then(m => m.GameHeaderModule),
            importGameHeaderV2: () => import('@egamings/sportsbook-core/sportsbook-dynamic-components/game-header-v2').then(m => m.GameHeaderV2Module),
            importGameHeaderAltenar: () => import('@egamings/sportsbook-core/sportsbook-dynamic-components/game-header-altenar').then(m => m.GameHeaderAltenarModule),
            importGameEvents: () => import('@egamings/sportsbook-core/sportsbook-dynamic-components/game-events').then(m => m.GamesEventsModule),
            importDynamicModulesPosition: () => import('@egamings/sportsbook-core/sportsbook-dynamic-components/modules-position-dynamic').then(m => m.ModulesPositionDynamicModule),
            importDynamicModuleInsert: () => import('@egamings/sportsbook-core/sportsbook-dynamic-components/module-insert-dynamic').then(m => m.ModuleInsertDynamicModule),
            importBetAccumulators: () => import('@egamings/sportsbook-core/sportsbook-dynamic-components/bet-accumulators').then(m => m.BetsAccumulatorModule),
            importGameTips: () => import('@egamings/sportsbook-core/sportsbook-dynamic-components/game-tips').then(m => m.GameTipsModule),
        })),
        SportsbookModuleSystemModule.forRoot({
            config: {
                useFactory: (siteConfig: SiteConfig) => {
                    return siteConfig.getModulesConfig();
                },
                deps: [SiteConfig],
            },
        }),

        SportsbookRouterModule.forRoot(),
        UIRouterModule.forChild(),


        TranslateModule.forRoot(),

    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: appLoadFactory,
            deps: [SiteConfig, HttpClient, TranslateService],
            multi: true,
        },
        SiteConfig,
        InjectorService,
        {
            provide: LOCALE_ID,
            deps: [SiteConfig],
            useFactory: (siteConfig) => availableLanguages.includes(siteConfig.getLanguage()) ? siteConfig.getLanguage() : 'en'
        },
        {
            provide: ErrorHandler,
            useClass: SentryErrorHandler
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
